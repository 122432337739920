<template>
  <v-form @submit.prevent="handleLogin" ref="form">
    <v-text-field
      class="mb-4"
      v-model="token"
      outlined
      :label="$t('LOGIN.FORM.TOKEN')"
      :disabled="isLoading"
      :rules="[v => !!v || $t('LOGIN.FORM.ERROR.TOKEN_REQUIRED')]"
      :hint="$t('LOGIN.FORM.TOKEN_INFO')"
      persistent-hint
    />
    <v-btn type="submit" block x-large dark class="action" :loading="isLoading">
      {{ $t("LOGIN.FORM.LOGIN") }}
    </v-btn>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      token: null,
      isLoading: false
    };
  },
  mounted() {
    /**
     * When component is mounted, the check is made to assign a token from .env file.
     * This happens only in dev environment.
     */
    if (process.env.NODE_ENV === "development") {
      if (process.env.VUE_APP_DEV_LOGIN_TOKEN) {
        this.token = process.env.VUE_APP_DEV_LOGIN_TOKEN;
      }
    }
  },
  methods: {
    ...mapActions({
      login: "auth/login"
    }),
    handleLogin() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.login({ token: this.token })
          .then(() => {
            this.$router.push({ name: "Home" });
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  }
};
</script>
