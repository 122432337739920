<template>
  <login-card />
</template>

<script>
import LoginCard from "@/views/Login/components/LoginCard";
export default {
  name: "Index",
  components: { LoginCard }
};
</script>
