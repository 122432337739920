<template>
  <v-card class="login-card" width="400" flat>
    <div class="logo-container">
      <v-img class="logo" src="@/assets/images/logo.png" max-width="278" />
    </div>
    <v-card-text class="text-center">
      <div class="text-h6 title font-weight-light">
        {{ $t("LOGIN.WELCOME.HEADER") }}
      </div>
      <div class="caption title">
        {{ $t("LOGIN.WELCOME.DESCRIPTION") }}
      </div>
    </v-card-text>
    <v-card-text>
      <login-form />
    </v-card-text>
  </v-card>
</template>

<script>
import LoginForm from "@/views/Login/components/LoginForm";
export default {
  name: "LoginCard",
  components: { LoginForm }
};
</script>

<style lang="scss">
.login-card {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 100%;
  justify-content: center;
  margin: auto;

  .logo-container {
    display: flex;
    justify-content: center;
  }
}
</style>
